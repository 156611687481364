body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  height: 6px;
  width: 0.5rem;
  background: #f1f1f1;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgb(158, 158, 158);
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
